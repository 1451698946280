import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import "select2";
export class DadosVocacionaisEditComponent {
    constructor(missaoService, casaComunitariaService, usuarioService, pessoaService, paisService, estadoService, cidadeService, elementoGrupoDominioService, filhoService, dadoVocacionalService, celibatarioService, apostoladoService, historicoMissionarioMissaoService, historicoMissionarioMissaoApostoladoService, relacionamentoService, sacerdoteService, pessoaBuilder, pessoaInfoBuilder, filhoBuilder, dadoVocacionalBuilder, celibatarioBuilder, apostoladoBuilder, historicoMissionarioMissaoBuilder, historicoMissionarioMissaoApostoladoBuilder, relacionamentoBuilder, sacerdoteBuilder, missionarioMissaoAtualService, salvarMissionarioMissaoAtualBuilder, dadosDetalhementoMissionarioMissaoAtualBuilder, swtAlert2Service, route, router) {
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.filhoService = filhoService;
        this.dadoVocacionalService = dadoVocacionalService;
        this.celibatarioService = celibatarioService;
        this.apostoladoService = apostoladoService;
        this.historicoMissionarioMissaoService = historicoMissionarioMissaoService;
        this.historicoMissionarioMissaoApostoladoService = historicoMissionarioMissaoApostoladoService;
        this.relacionamentoService = relacionamentoService;
        this.sacerdoteService = sacerdoteService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.filhoBuilder = filhoBuilder;
        this.dadoVocacionalBuilder = dadoVocacionalBuilder;
        this.celibatarioBuilder = celibatarioBuilder;
        this.apostoladoBuilder = apostoladoBuilder;
        this.historicoMissionarioMissaoBuilder = historicoMissionarioMissaoBuilder;
        this.historicoMissionarioMissaoApostoladoBuilder = historicoMissionarioMissaoApostoladoBuilder;
        this.relacionamentoBuilder = relacionamentoBuilder;
        this.sacerdoteBuilder = sacerdoteBuilder;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.salvarMissionarioMissaoAtualBuilder = salvarMissionarioMissaoAtualBuilder;
        this.dadosDetalhementoMissionarioMissaoAtualBuilder = dadosDetalhementoMissionarioMissaoAtualBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.formaVidaSelecionadaId = null;
        this.estadoVidaSelecionadoId = null;
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.missaoSelecionadaId = null;
        this.tipoApostoladoSelecionadoId = null;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.filho = this.filhoBuilder.getInstance();
        this.pessoaPaiSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaMaeSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaFilhoSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado =
            this.pessoaInfoBuilder.getInstance();
        this.pessoaRelacionamentoSelecionada = this.pessoaInfoBuilder.getInstance();
        this.dadoVocacional = this.dadoVocacionalBuilder.getInstance();
        this.celibatario = this.celibatarioBuilder.getInstance();
        this.apostolado = this.apostoladoBuilder.getInstance();
        this.historicoMissionarioMissao =
            this.historicoMissionarioMissaoBuilder.getInstance();
        this.historicoMissionarioMissaoApostolado =
            this.historicoMissionarioMissaoApostoladoBuilder.getInstance();
        this.relacionamento = this.relacionamentoBuilder.getInstance();
        this.sacerdote = this.sacerdoteBuilder.getInstance();
        this.filhos = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.apostolados = new Array();
        this.historicosMissionarioMissao = new Array();
        this.historicosMissionarioMissaoApostolados =
            new Array();
        this.salvarMissionarioMissaoAtual =
            this.salvarMissionarioMissaoAtualBuilder.getInstance();
        this.dadosDetalhamentoMissionarioMissaoAtual =
            this.dadosDetalhementoMissionarioMissaoAtualBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.carregarMissoes();
            this.carregarFormasVida();
            this.carregarEstadoVida();
            this.carregarNivelFormacao();
            this.carregarTipoRelacionamento();
            this.carregarPaises();
            this.carregarTipoApostolado();
            this.carregarApostolados();
            yield this.getUsuarioAtual();
            yield this.carregarPessoa();
            yield this.carregarDadoVocacional();
            yield this.carregarCelibatario();
            yield this.carregarRelacionamento();
            yield this.carregarSacerdote();
            this.carregarFilhos();
            this.carregarHistoricoMissionarioMissao();
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.getAllAnyStatus();
                this.missoes = response.entity;
                this.missoes = this.missoes.sort((a, b) => a.nome.localeCompare(b.nome, "fi"));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCasasComunitarias(missaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.porMissao(missaoId);
                this.casasComunitarias = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFormasVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: "FORMA_VIDA",
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.formasVida = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarEstadoVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: "ESTADO_VIDA",
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.estadosVida = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.pessoaFormadorPessoalSelecionado =
                        this.pessoaInfoBuilder.getInstance();
                    this.pessoaFormadorPessoalSelecionado.nome =
                        this.dadoVocacional.formadorPessoalPessoaNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCelibatario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celibatarioService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.celibatario = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRelacionamento() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.relacionamentoService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.relacionamento = response.entity;
                    this.pessoaRelacionamentoSelecionada =
                        this.pessoaInfoBuilder.getInstance();
                    if (this.relacionamento.pessoa1Id == this.pessoa.id) {
                        this.pessoaRelacionamentoSelecionada.nome =
                            this.relacionamento.pessoa2Nome;
                    }
                    else if (this.relacionamento.pessoa2Id == this.pessoa.id) {
                        this.pessoaRelacionamentoSelecionada.nome =
                            this.relacionamento.pessoa1Nome;
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarSacerdote() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.sacerdoteService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.sacerdote = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarHistoricoMissionarioMissao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.historicoMissionarioMissaoService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.historicosMissionarioMissao = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.historicoMissionarioMissaoApostoladoService.findByHistoricoMissionarioMissaoId(historicoMissionarioMissaoID);
                if (response.entity != undefined && response.entity != null) {
                    this.historicosMissionarioMissaoApostolados = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarNivelFormacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: "NIVEL_FORMACAO",
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.niveisFormacao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarTipoRelacionamento() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: "TIPO_RELACIONAMENTO",
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.tiposRelacionamento = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarTipoApostolado() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: "TIPO_APOSTOLADO",
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.tiposApostolado = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostolados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                ativo: null,
            };
            try {
                let response = yield this.apostoladoService.pesquisar(request);
                this.apostolados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostoladosPorTipo(tipoApostoladoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.apostoladosSelecionados = this.apostolados.filter((a) => a.tipoId == tipoApostoladoId);
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);
                this.pessoa = response.entity;
                this.formaVidaSelecionadaId = this.pessoa.formaVidaId;
                this.estadoVidaSelecionadoId = this.pessoa.estadoVidaId;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFilhos() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = null;
                if (this.pessoa.genero == "masculino") {
                    response = yield yield this.filhoService.porPaiId(this.pessoa.id);
                }
                else if (this.pessoa.genero == "feminino") {
                    response = yield yield this.filhoService.porMaeId(this.pessoa.id);
                }
                this.filhos = response.entity;
                if (this.filhos.length > 0) {
                    this.hasFilho = true;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarFormaVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    pessoaId: this.pessoa.id,
                    elementoGrupoDominioFormaVidaId: this.formaVidaSelecionadaId,
                };
                let response = yield this.pessoaService.atualizarFormaVida(request);
                this.swtAlert2Service.successAlert(response.message);
                yield this.carregarPessoa();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarEstadoVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    pessoaId: this.pessoa.id,
                    elementoGrupoDominioEstadoVidaId: this.estadoVidaSelecionadoId,
                };
                let response = yield this.pessoaService.atualizarEstadoVida(request);
                this.swtAlert2Service.successAlert(response.message);
                yield this.carregarPessoa();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.dadoVocacional.id == null) {
                    this.dadoVocacional.pessoaId = this.pessoa.id;
                    let response = yield this.dadoVocacionalService.create(this.dadoVocacional);
                    this.dadoVocacional = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.dadoVocacionalService.update(this.dadoVocacional.id, this.dadoVocacional);
                    this.dadoVocacional = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarCelibatario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.celibatario.id == null) {
                    this.celibatario.pessoaId = this.pessoa.id;
                    let response = yield this.celibatarioService.create(this.celibatario);
                    this.celibatario = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.celibatarioService.update(this.celibatario.id, this.celibatario);
                    this.celibatario = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarRelacionamento() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.relacionamento.pessoa2IsMembro == null ||
                    this.relacionamento.tipoRelacionamentoId == null) {
                    this.swtAlert2Service.warningAlert("Preencha todos os campos do formulário, incluindo pessoa, tipo de relacionamento e data(s) do relacionamento.");
                }
                else if (this.relacionamento.id == null) {
                    this.relacionamento.pessoa1Id = this.pessoa.id;
                    this.relacionamento.pessoa1IsMembro = true;
                    let response = yield this.relacionamentoService.create(this.relacionamento);
                    this.relacionamento = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.relacionamentoService.update(this.relacionamento.id, this.relacionamento);
                    this.relacionamento = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarSacerdote() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.sacerdote.id == null) {
                    this.sacerdote.pessoaId = this.pessoa.id;
                    let response = yield this.sacerdoteService.create(this.sacerdote);
                    this.sacerdote = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.sacerdoteService.update(this.sacerdote.id, this.sacerdote);
                    this.sacerdote = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPaises() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.paisService.findAll();
                this.paises = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstados(paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    paisId: paisId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000,
                };
                let response = yield this.estadoService.buscarEstados(request);
                this.estados = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCidades(estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    estadoId: estadoId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000,
                };
                let response = yield this.cidadeService.buscarCidades(request);
                this.cidades = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    salvarFilho(filho) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.pessoa.genero == "masculino") {
                    filho.paiId = this.pessoa.id;
                }
                else if (this.pessoa.genero == "feminino") {
                    filho.maeId = this.pessoa.id;
                }
                if (filho.isMembro == true) {
                    filho.filhoId = this.pessoaFilhoSelecionado.id;
                }
                let response = yield this.filhoService.create(filho);
                this.swtAlert2Service.successAlert(response.message);
                this.filho = this.filhoBuilder.getInstance();
                this.pessoaPaiSelecionado = this.pessoaInfoBuilder.getInstance();
                this.pessoaMaeSelecionado = this.pessoaInfoBuilder.getInstance();
                this.pessoaFilhoSelecionado = this.pessoaInfoBuilder.getInstance();
                this.maeIsMembro = false;
                this.paiIsMembro = false;
                yield this.carregarFilhos();
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    salvarHistoricoMissionarioMissao(historicoMissionarioMissao) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (historicoMissionarioMissao.id == null) {
                    historicoMissionarioMissao.missaoId = this.missaoSelecionadaId;
                }
                let response = null;
                if (historicoMissionarioMissao.id == null) {
                    historicoMissionarioMissao.pessoaId = this.pessoa.id;
                    response = yield this.historicoMissionarioMissaoService.create(historicoMissionarioMissao);
                    this.historicoMissionarioMissao =
                        this.historicoMissionarioMissaoBuilder.getInstance();
                }
                else {
                    response = yield this.historicoMissionarioMissaoService.update(historicoMissionarioMissao.id, historicoMissionarioMissao);
                }
                this.swtAlert2Service.successAlert(response.message);
                yield this.carregarHistoricoMissionarioMissao();
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    salvarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoApostolado) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (historicoMissionarioMissaoApostolado.apostoladoId != null) {
                if (historicoMissionarioMissaoApostolado.duracaoAnos == null ||
                    historicoMissionarioMissaoApostolado.duracaoMeses == null) {
                    this.swtAlert2Service.warningAlert("Informe a duração de anos e meses. Caso não saiba o período completo, coloque o tempo aproximado.");
                }
                else {
                    try {
                        let historicoMissionarioMissaoId = historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId;
                        let response = null;
                        if (historicoMissionarioMissaoApostolado.id != null) {
                            response =
                                yield this.historicoMissionarioMissaoApostoladoService.update(historicoMissionarioMissaoApostolado.id, historicoMissionarioMissaoApostolado);
                        }
                        else {
                            response =
                                yield this.historicoMissionarioMissaoApostoladoService.create(historicoMissionarioMissaoApostolado);
                        }
                        this.swtAlert2Service.successAlert(response.message);
                        yield this.carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoId);
                        this.historicoMissionarioMissaoApostolado =
                            this.historicoMissionarioMissaoApostoladoBuilder.getInstance();
                        this.historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId =
                            historicoMissionarioMissaoId;
                    }
                    catch (e) {
                        this.swtAlert2Service.errorAlert(e.error.errors);
                    }
                }
            }
            else {
                this.swtAlert2Service.warningAlert("Preencha os dados do formulário antes de salvar o apostolado.");
            }
        });
    }
    deletarFilho(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja deletar esse filho?")) {
                try {
                    let response = yield this.filhoService.delete(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.carregarFilhos();
                }
                catch (e) {
                    this.swtAlert2Service.errorAlert(e.error.errors);
                }
            }
        });
    }
    deletarCelibatario(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja deletar os dados do celibato?")) {
                try {
                    let response = yield this.celibatarioService.delete(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.celibatario = this.celibatarioBuilder.getInstance();
                    this.carregarCelibatario();
                }
                catch (e) {
                    this.swtAlert2Service.errorAlert(e.error.errors);
                }
            }
        });
    }
    deletarRelacionamento(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja deletar os dados do relacionamento?")) {
                try {
                    let response = yield this.relacionamentoService.delete(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.relacionamento = this.relacionamentoBuilder.getInstance();
                    this.pessoaRelacionamentoSelecionada =
                        this.pessoaInfoBuilder.getInstance();
                    this.carregarRelacionamento();
                }
                catch (e) {
                    this.swtAlert2Service.errorAlert(e.error.errors);
                }
            }
        });
    }
    deletarSacerdote(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja deletar os dados do sacerdócio?")) {
                try {
                    let response = yield this.sacerdoteService.delete(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.sacerdote = this.sacerdoteBuilder.getInstance();
                    this.carregarSacerdote();
                }
                catch (e) {
                    this.swtAlert2Service.errorAlert(e.error.errors);
                }
            }
        });
    }
    deletarHistoricoMissionarioMissao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja deletar os dados de histórico na missão?")) {
                try {
                    let response = yield this.historicoMissionarioMissaoService.delete(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.carregarHistoricoMissionarioMissao();
                }
                catch (e) {
                    this.swtAlert2Service.errorAlert(e.error.errors);
                }
            }
        });
    }
    deletarHistoricoMissionarioMissaoApostolado(id, historicoMissionarioMissaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja deletar os dados de histórico deste apostolado na missão?")) {
                try {
                    let response = yield this.historicoMissionarioMissaoApostoladoService.delete(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoId);
                }
                catch (e) {
                    this.swtAlert2Service.errorAlert(e.error.errors);
                }
            }
        });
    }
    selecionarPai(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.filho.paiId = pessoa.id;
            this.pessoaPaiSelecionado = pessoa;
            $("#listagem-pessoa-pai").modal("hide");
        });
    }
    selecionarMae(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.filho.maeId = pessoa.id;
            this.pessoaMaeSelecionado = pessoa;
            $("#listagem-pessoa-mae").modal("hide");
        });
    }
    selecionarFilho(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.filho.filhoId = pessoa.id;
            this.pessoaFilhoSelecionado = pessoa;
            $("#listagem-pessoa-filho").modal("hide");
        });
    }
    selecionarFormadorPessoal(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            this.pessoaFormadorPessoalSelecionado = pessoa;
            $("#listagem-pessoa-formador-pessoal").modal("hide");
        });
    }
    selecionarPessoaRelacionamento(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.relacionamento.pessoa2Id = pessoa.id;
            this.relacionamento.pessoa2IsMembro = true;
            this.pessoaRelacionamentoSelecionada = pessoa;
            $("#listagem-pessoa-relacionamento").modal("hide");
        });
    }
    configModalHistoricoApostolado(historicoMissionarioMissaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId =
                historicoMissionarioMissaoId;
            this.carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoId);
        });
    }
}
