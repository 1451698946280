import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { environment } from "src/environments/environment";
export class ElementoGrupoDominioLocalService extends GenericService {
    constructor(http, router) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.setEntityType("elementogrupodominio");
    }
    buscarElementosGrupoDominio(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(this.apiURL + "/buscar", JSON.stringify(busca), {
                headers: this.getHeaders(),
            })
                .toPromise();
        });
    }
    buscarPorGrupoDominioNome(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(this.apiURL + "/pornomegrupo", JSON.stringify(busca), {
                headers: this.getHeaders(),
            })
                .toPromise();
        });
    }
    buscarPorGrupoDominioNomeModuloFormacao(nome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let url = `${environment.moduloFormacao.urlModuloFormacao}secured/elementogrupodominio/pornomegrupo?nome=${nome}`;
            return this.http.get(url, { headers: this.getHeaders() }).toPromise();
        });
    }
}
