import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
export class ParecerCelulaAutoridadeComponent {
    constructor(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, formadorComunitarioService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, elementoGrupoDominioLocalService, caminhoFormativoService, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCelulaAssociada = false;
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            this.celulaId = Number(this.route.snapshot.paramMap.get("id"));
            yield this.carregarCorStatusVocacional();
            yield this.carregarMembrosCelula();
        });
    }
    carregarQuestionarioConfiguracao(questionarioId) {
        const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe((questionarioConfiguracao) => (this.questionarioConfiguracao = questionarioConfiguracao));
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarCorStatusVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.listaCorStatusVocacional =
                    yield this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao("COR_STATUS_VOCACIONAL");
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarQuestionario(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorPessoaId(pessoaId);
                if (response != undefined && response != null) {
                    return response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMembrosCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.membrosCelula = yield this.membroService.listarMembrosCelula(this.celulaId);
                this.totalDePaginasMembros = 1;
                if (this.membrosCelula.length > 0) {
                    this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                this.membrosCelula.forEach((m) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (!this.questionarioFormando || !this.questionarioFormando.id) {
                        this.questionarioFormando = yield this.carregarQuestionario(m.pessoaId);
                        if (this.questionarioFormando) {
                            if (!this.questionarioConfiguracao) {
                                this.carregarQuestionarioConfiguracao(this.questionarioFormando.questionarioId);
                            }
                        }
                    }
                    if (!this.questionarioConfiguracao) {
                        this.carregarQuestionarioConfiguracao(this.questionarioFormando.questionarioId);
                    }
                    if (m.imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                        this.imagemService.createImage(blogImage, m);
                    }
                }));
                if (this.membrosCelula.length > 0) {
                    let questionarioFormando = yield this.carregarQuestionario(this.membrosCelula[0].pessoaId);
                    if (questionarioFormando != null) {
                        if (!this.questionarioConfiguracao) {
                            this.carregarQuestionarioConfiguracao(questionarioFormando.questionarioId);
                        }
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(JSON.stringify(err.error));
            }
        });
    }
    cadastrarConsideracaoConselhoLocal(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.caminhoFormativoService.cadastrarConsideracaoConselhoLocal(membro);
                yield this.carregarMembrosCelula();
                this.swtAlert2Service.successAlert("Parecer de consideração do Conselho Local atualizado com sucesso!");
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.carregarMembrosCelula();
    }
    concordanciaConselhoLocal(membro) {
        if (membro.consideracaoCondorda === true) {
            membro.caminhoFormativoFeedback =
                membro.caminhoFormativoSugeridoFormadorComunitario;
            membro.consideracaoConselhoLocal = "";
        }
        else {
            membro.caminhoFormativoFeedback = "";
        }
    }
    copiarTextoConsideracaoConselhoLocalParaFeedback(membro) {
        membro.caminhoFormativoFeedback = membro.consideracaoConselhoLocal;
    }
    obterCorDaLinha(membro) {
        let cssObject = {};
        if (membro.corStatusVocacionalValor != null) {
            cssObject["background-color"] = membro.corStatusVocacionalValor;
        }
        if (membro.corStatusVocacionalNome != null &&
            membro.corStatusVocacionalNome.localeCompare("Desligamento") == 0) {
            cssObject["color"] = "#fff";
        }
        return cssObject;
    }
}
