import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
export class ColegiadoComponent {
    constructor(usuarioService, imagemService, pessoaService, coordenadorColegiadoService, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.coordenadorColegiadoService = coordenadorColegiadoService;
        this.swtAlert2Service = swtAlert2Service;
    }
    ngOnInit() {
        this.initializer();
        this.paginacaoConfig = {
            page: 0,
            size: 10,
            totalPages: 0,
            totalElements: 0,
        };
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            yield this.carregarFormadoresCelula();
        });
    }
    carregarFormadoresCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.coordenadorColegiadoService.obterFormadoresPorMissaoDoCoordenadorColegiado(this.paginacaoConfig.page, this.paginacaoConfig.size);
                this.formadoresDaMissao = response.content;
                this.paginacaoConfig.totalPages = response.totalPages;
                this.paginacaoConfig.totalElements = response.totalElements;
                if (this.formadoresDaMissao.length > 0) {
                    this.formadoresDaMissao = this.formadoresDaMissao.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.formadoresDaMissao.length; i++) {
                    if (this.formadoresDaMissao[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.formadoresDaMissao[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.formadoresDaMissao[i]);
                    }
                    if (this.formadoresDaMissao[i].questionarioFormandoStatus == "NAOINICIADO") {
                        this.formadoresDaMissao[i].questionarioFormandoStatus =
                            "Não Iniciado";
                    }
                    if (this.formadoresDaMissao[i].parecerStatus == "NAOINICIADO") {
                        this.formadoresDaMissao[i].parecerStatus = "Não Iniciado";
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.paginacaoConfig.page = botao.numero - 1;
        this.carregarFormadoresCelula();
    }
}
